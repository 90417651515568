import * as React from 'react';
import SEO from '../../components/global/seo';
import OtherLayout from '../../components/other/other-layout';
import ContactMdx from '../../components/contact/contact.mdx';

import './index.scss';

const ContactPage = () => (
  <OtherLayout className="contact-page">
    <SEO title="Contact" />

    <ContactMdx/>

  </OtherLayout>
);

export default ContactPage;
