import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import PhotoThierryChatel from '../global/photo-thierry-chatel';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <PhotoThierryChatel mdxType="PhotoThierryChatel" />
    <h2>{`Thierry CHATEL`}</h2>
    <ul>
      <li parentName="ul">{`par email (de préférence) : `}<strong parentName="li"><a parentName="strong" {...{
            "href": "mailto:tchatel@obi2b.com"
          }}>{`tchatel@obi2b.com`}</a></strong></li>
      <li parentName="ul">{`par téléphone : `}<strong parentName="li">{`06 17 40 50 20`}</strong></li>
      <li parentName="ul">{`LinkedIn : `}<a parentName="li" {...{
          "href": "https://fr.linkedin.com/in/tchatel"
        }}>{`https://fr.linkedin.com/in/tchatel`}</a></li>
      <li parentName="ul">{`Twitter : `}<a parentName="li" {...{
          "href": "https://twitter.com/ThierryChatel"
        }}>{`@ThierryChatel`}</a></li>
    </ul>
    <h3>{`DOMAINES DE PRÉDILECTION :`}</h3>
    <ul>
      <li parentName="ul">{`architecture logicielle`}</li>
      <li parentName="ul">{`applications web, Angular`}</li>
      <li parentName="ul">{`bonnes pratiques de développement`}</li>
      <li parentName="ul">{`modélisation objet`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      